<template>
  <div>
    <Permissions package="inset-enrollment" :superUserOnly="false" />

    <ManageGroupSidebar
      :visible="showMangementSidebar"
      :entity="entityData"
      :fields="fieldsInGroup"
      :allFieldSupplySummaries="allFieldSupplySummaries"
      :allClientProfiles="allClientProfiles"
      @closeSidebar="handleManagementSidebarClose"
      @openLOIModal="handleLOIModalOpen"
      @renderGroupData="handleUpdateGroupData"
    />

    <LetterOfIntentModal
      v-if="openLOIModal"
      :data="dataForLOIModal"
      @close-modal="handleLOIModalClose"
      @generate-loi="generateLOI"
    />

    <ClientProfileModificationModal
      v-if="openProfileModificationModal"
      @close-modal="handleLOIModalClose"
    />

    <v-progress-circular
      v-if="loading"
      indeterminate
      :size="48"
      color="#79c61c"
    />
    <div v-else-if="error">{{ error }}</div>
    <div v-else>
      <div class="d-flex justify-space-between align-center mb-4">
        <div class="d-flex align-center">
          <router-link to="/contract-groups">
            <v-icon>mdi-chevron-left</v-icon>
          </router-link>
          <h1 class="mb-0 font-weight-bold">{{ groupDetailData["name"] }}</h1>
        </div>

        <v-btn v-b-toggle.sidebar-right :ripple="false" outlined height="44">
          Manage Group & Contracts
        </v-btn>
      </div>
  
      <div class="position-relative mb-6">
        <v-progress-circular class="rollup-loader" v-if="modifyingFssState" indeterminate :size="48" color="#79c61c" />
        
        <v-data-table
          :class="`rollup-table ${modifyingFssState ? 'fss-loading' : ''}`"
          :style="`--custom-table-height: ${
            showRollupTable
              ? programRollupRows.length >= 4 ? '210px' : `${58 + programRollupRows.length * 44}px`
              : '58px'
          }`"
          :headers="ROLLUP_TABLE_HEADERS"
          :items="programRollupRows"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:header.available>
            <div class="d-flex align-center flex-wrap">
              <p class="w-100 ma-0">Available</p>
              <p class="w-100 ma-0">Available Program space</p>
            </div>
          </template>

          <template v-slot:header.enrolled>
            <div class="d-flex align-center flex-wrap">
              <p class="w-100 ma-0">Enrolled</p>
              <p class="w-100 ma-0">Total Enrollment requests</p>
            </div>
          </template>

          <template v-slot:header.enrolledFromGroup>
            <div class="d-flex align-center flex-wrap">
              <p class="w-100 ma-0">Enrolled from group</p>
              <p class="w-100 ma-0">Requested from this field set</p>
            </div>
          </template>

          <template v-slot:header.remaining>
            <div class="d-flex align-center flex-wrap">
              <p class="w-100 ma-0">Remaining</p>
              <p class="w-100 ma-0">To be filled</p>
            </div>
          </template>

          <template v-slot:header.surplus>
            <div class="d-flex align-center flex-wrap">
              <p class="w-100 ma-0">Surplus</p>
              <p class="w-100 ma-0">Acreage above available space</p>
            </div>
          </template>
        </v-data-table>

        <v-simple-table class="rollup-table-summary">
          <thead>
            <th></th>
            <th></th>
            <th>{{ lastRowSummary['available'] }} AC</th>
            <th>{{ lastRowSummary['enrolled'] }} AC</th>
            <th>{{ lastRowSummary['enrolledFromGroup'] }} AC</th>
            <th>{{ lastRowSummary['remaining'] }} AC</th>
            <th
              class="collapsible-fxn" @click="toggleShowRollup"
              :style="`--chevron-down-pseudo-src: url('${chevronUp}'); --flipped: ${showRollupTable ? '0deg' : '180deg'}`"
            >{{ lastRowSummary['surplus'] }} AC</th>
          </thead>
        </v-simple-table>
      </div>

      <v-card outlined class="px-6 py-4">
        <v-data-table
          :headers="HEADERS"
          :items="items"
          show-select
          hide-default-footer
          disable-pagination
          :custom-filter="handleDataTableFiltering"
        >
          <template v-slot:top="props">
            <div
              v-bind="props"
              class="d-flex justify-space-between align-center mb-4"
            >
              <div class="d-flex align-center flex-wrap">
                <v-card-title class="pa-0 ma-0 w-100 order-1">{{ items.length }} Fields</v-card-title>
                <v-card-subtitle class="pa-0 ma-0 mb-2 w-100 order-0">Enrollment Progress</v-card-subtitle>
              </div>
              <div class="d-flex justify-end">
                <v-select
                  :class="`mr-2 ${allUniqueCrops.length == selectedCrops.length ? 'all-selected' : ''}`"
                  solo
                  multiple
                  :clearable="allUniqueCrops.length != selectedCrops.length"
                  hide-details
                  :items="allUniqueCrops"
                  v-model="selectedCrops"
                  label="Crops"
                  placeholder="Crops"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular" v-if="selectedCrops.length == allUniqueCrops.length">
                        All Crops
                      </p>
                      <p class="ma-0 text-subtitle-2 font-weight-regular" v-else>
                        Crops <span>{{ selectedCrops.length }}</span>
                      </p>
                    </div>
                  </template>
                </v-select>

                <v-select
                  :class="`mr-2 ${allUniqueFarms.length == selectedFarms.length ? 'all-selected' : ''}`"
                  solo
                  multiple
                  :clearable="allUniqueFarms.length != selectedFarms.length"
                  hide-details
                  :items="allUniqueFarms"
                  v-model="selectedFarms"
                  label="Farms"
                  placeholder="Farms"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular" v-if="selectedFarms.length == allUniqueFarms.length">
                        All Farms
                      </p>
                      <p class="ma-0 text-subtitle-2 font-weight-regular" v-else>
                        Farms <span>{{ selectedFarms.length }}</span>
                      </p>
                    </div>
                  </template>
                </v-select>

                <v-select
                  :class="`mr-2 ${allStatuses.length == selectedStatuses.length ? 'all-selected' : ''}`"
                  solo
                  multiple
                  :clearable="allStatuses.length != selectedStatuses.length"
                  hide-details
                  :items="allStatuses"
                  v-model="selectedStatuses"
                  label="Statuses"
                  placeholder="Statuses"
                >
                  <template v-slot:selection="{ item, index }">
                    <div v-if="index == 0">
                      <p class="ma-0 text-subtitle-2 font-weight-regular" v-if="selectedStatuses.length == allStatuses.length">
                        All statuses
                      </p>
                      <p class="ma-0 text-subtitle-2 font-weight-regular" v-else>
                        Statuses <span>{{ selectedStatuses.length }}</span>
                      </p>
                    </div>
                  </template>
                </v-select>

                <v-btn
                  disabled
                  :ripple="false"
                  class="mr-3"
                  outlined
                  height="42px"
                >
                  <v-icon>mdi-magnify</v-icon>
                  <span class="ml-1">Search</span>
                </v-btn>
              </div>
            </div>
          </template>

          <template v-slot:item.field="props">
            <div class="d-flex align-center py-3">
              <img class="thumbnail" :src="props.value['thumbnail']" @error="(e) => e.target.src = fallback">
              <p class="mb-0 ml-4 field-name">{{ props.value['name'] }}</p>
            </div>
          </template>

          <template v-slot:item.crop="props">
            <div v-bind="props">
              <p class="mb-0 capitalize">{{ props.value['value'] }}</p>
            </div>
          </template>
          
          <template v-slot:item.status="props">
            <v-chip v-if="props.value['value'] == AVAILABLE" color="gray">
              Available
            </v-chip>
            <v-chip v-else-if="props.value['value'] == LOCKED" color="green">
              Enrollment requested
            </v-chip>
            <v-chip v-else color="gray">
              Unavailable
            </v-chip>
          </template>

          <template v-slot:item.requestEnrollment="props">
            <!-- if disabled, show a tooltip or sm -->
             <!--  :disabled="props.value['disabled'] || modifyingFssState" -->
            <!-- <v-switch
              v-model="fieldsSetForEnrollment"
              :value="props.item['id']"
              disabled
              :ripple="false"
              hide-details
              inset
            /> -->
            <v-tooltip content-class="tooltip-no-transition" bottom max-width="300px">
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="d-flex custom-toggler disabled">
                  <a
                    :class="`
                      px-4 py-3 d-flex align-center justify-center w-100 
                      ${fieldsSetForEnrollment.includes(props.item['id']) ? 'selected' : ''}
                    `"
                  >
                    Yes
                  </a>
                  <a
                    :class="`
                      px-4 py-3 d-flex align-center justify-center w-100 
                      ${!fieldsSetForEnrollment.includes(props.item['id']) ? 'selected' : ''}
                    `"
                  >
                    No
                  </a>
                </div>
              </template>

              <span>The ability to request enrollment for fields is coming soon! Right now, 'Yes' is selected if you've requested a field be enrolled.</span>
            </v-tooltip>
          </template>

          <template v-slot:item.practicesDetail="props">
            <p class="ma-0 text-capitalize">{{ props.value.join(', ') }}</p>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import chevronUp from "@/assets/images/chevron-up.svg"
import fallback from "@/assets/images/fallback.png"
import Permissions from "@/components/permissions/Permissions"
import ManageGroupSidebar from "../components/contractGroups/ManageGroupSidebar.vue"
import LetterOfIntentModal from "../components/modals/LetterOfIntentModal.vue"
import FieldsAPI from "@/api/FieldsAPI"
import { getGroupDetail, getGroupEditingDataset, updateFssStatuses } from "@/api/ContractGroupAPI"
import { CROP_DISPLAY_NAME, US_STATES } from "@/constants"
import { mapState } from "vuex";
import ClientProfileModificationModal from "../components/modals/ClientProfileModificationModal.vue";

// temp constants
const HEADERS = [
  { text: "Field", value: "field", filter: v => v['selectedFarms'].includes(v['farm']) },
  { text: "Acres", value: "acres" },
  { text: "Crop", value: "crop", filter: v => v['selectedCrops'].includes(v['value']) },
  { text: "Status", value: "status", filter: v => v['selectedStatuses'].includes(v['value']) },
  { text: "Request Enrollment", value: "requestEnrollment", sortable: false },
  { text: "Practices", value: "practices" },
  { text: "Practices", value: "practicesDetail" },
  { text: "Region", value: "region" },
  // { text: "Submit Date", value: "submitDate" },
  // { text: "Deadline", value: "deadline" },
]

const ROLLUP_TABLE_HEADERS = [
 { text: "Region", value: "region" },
 { text: "Crop", value: "crop" },
 { text: "", value: "available", sortable: false },
 { text: "", value: "enrolled", sortable: false },
 { text: "", value: "enrolledFromGroup", sortable: false },
 { text: "", value: "remaining", sortable: false },
 { text: "", value: "surplus", sortable: false },
]

const AVAILABLE = "available"
const NEEDS_SUBMISSION = "needs_submission"
const ENROLLMENT_REQUESTED = "enrollment_requested"
const LOCKED = "locked"
const OPEN = "open"
const MATCHED = "matched"

const SET_TO_LOCKED = "set_to_locked"
const SET_TO_AVAILABLE = "set_to_available"

export default {
  name: "ContractGroupDetail",
  components: {
    Permissions,
    ManageGroupSidebar,
    LetterOfIntentModal,
    ClientProfileModificationModal,
  },
  data() {
    return {
      selectedCrops: [],
      selectedFarms: [],
      selectedStatuses: [AVAILABLE, LOCKED],
      showRollupTable: true,
      modifyingFssState: false,
      openProfileModificationModal: false,
      showMangementSidebar: false,
      openLOIModal: false,
      loading: true,
      error: false,
      groupDetailData: null,
      groupProgramRollup: {
        order_details: [],
        order_allocations_within_group: {}
      },
      dataForLOIModal: null,
      dataForProfileModificationModal: null,
      allFieldSupplySummaries: [],
      allClientProfiles: [],
      fieldsSetForEnrollment: [],
      HEADERS,
      ROLLUP_TABLE_HEADERS,
      AVAILABLE,
      NEEDS_SUBMISSION,
      ENROLLMENT_REQUESTED,
      LOCKED,
      OPEN,
      MATCHED,
      fallback,
      chevronUp,
      allStatuses: [AVAILABLE, LOCKED]
    }
  },
  mounted() {
    this.getGroupData()
  },
  computed: {
    ...mapState({
      org: state => state.Organization.organization,
      year: state => state.Organization.year,
    }),
    allUniqueFarms() {
      const allFarms = []

      if (this.groupDetailData == null) return allFarms

      for (const { field_farm } of this.groupDetailData['fieldsupply']) {
        if (!allFarms.includes(field_farm)) allFarms.push(field_farm)
      }

      return allFarms.sort()
    },
    allUniqueCrops() {
      const allCrops = []

      if (this.groupDetailData == null) return allCrops

      for (const { crop } of this.groupDetailData['fieldsupply']) {
        if (!allCrops.includes(crop)) allCrops.push(crop)
      }

      return allCrops.sort()
    },
    programRollupRows() {
      const rowsByRegion = {}

      for (const { id, region, params, program_allotment } of this.groupProgramRollup['order_details']) {
        for (const regName of region) {
          const reg = US_STATES.find(({ text }) => text == regName)['value']
          if (!(reg in rowsByRegion)) rowsByRegion[reg] = {
            crops: [
              {
                cropIds: params['crop_id'],
                crop: params['crop_id'].map(c => CROP_DISPLAY_NAME[c]).join(', '),
                available: program_allotment[0]['total_allotment'],
                enrolled: program_allotment[0]['allocated'],
                enrolledFromGroup: this.groupProgramRollup['order_allocations_within_group'][id]
              }
            ]
          }
          else {
            const found = rowsByRegion[reg]['crops'].find(({ cropIds }) => cropIds.every(id => params['crop_id'].includes(id)))

            if (found == null) {
              rowsByRegion[reg]['crops'].push({
                cropIds: params['crop_id'],
                crop: params['crop_id'].map(c => CROP_DISPLAY_NAME[c]).join(', '),
                available: program_allotment[0]['total_allotment'],
                enrolled: program_allotment[0]['allocated'],
                enrolledFromGroup: this.groupProgramRollup['order_allocations_within_group'][id]
              })
            }
            else {
              found['available'] += program_allotment[0]['total_allotment'],
              found['enrolled'] += program_allotment[0]['allocated']
              found['enrolledFromGroup'] += this.groupProgramRollup['order_allocations_within_group'][id]
            }
          }
        }
      }

      const rows = []

      for (const region in rowsByRegion) {
        for (let i=0; i<rowsByRegion[region]['crops'].length; i++) {
          const { crop, enrolled, available, enrolledFromGroup } = rowsByRegion[region]['crops'][i]

          rows.push({
            region,
            crop,
            available: available.toLocaleString(),
            enrolled: enrolled.toLocaleString(),
            enrolledFromGroup: Number(enrolledFromGroup.toFixed(0)).toLocaleString(),
            surplus: available - enrolled <= 0 ? Math.abs(available - enrolled).toLocaleString() : '0',
            remaining: available - enrolled >= 0 ? Math.abs(available - enrolled).toLocaleString() : '0'
          })
        }
      }

      return rows
    },
    lastRowSummary() {
      return {
        region: null,
        crop: null,
        available: this.programRollupRows
          .reduce((acc, cur) => 
            acc += parseFloat(cur['available'].replaceAll(',', '')), 0
          ).toLocaleString(),
        enrolled: this.programRollupRows
          .reduce((acc, cur) => 
            acc += parseFloat(cur['enrolled'].replaceAll(',', '')), 0
          ).toLocaleString(),
        enrolledFromGroup: this.programRollupRows
          .reduce((acc, cur) => 
            acc += parseFloat(cur['enrolledFromGroup'].replaceAll(',', '')), 0
          ).toLocaleString(),
        surplus: this.programRollupRows
          .reduce((acc, cur) => 
            acc += parseFloat(cur['surplus'].replaceAll(',', '')), 0
          ).toLocaleString(),
        remaining: this.programRollupRows
          .reduce((acc, cur) => 
            acc += parseFloat(cur['remaining'].replaceAll(',', '')), 0
          ).toLocaleString()
      }
    },
    entityData() {
      if (this.groupDetailData == null || this.org == null) return null

      return {
        id: this.groupDetailData["id"],
        org: this.org["id"],
        year: this.year,
        name: this.groupDetailData["name"],
        notes: this.groupDetailData["notes"],
        cpProfiles: this.groupDetailData["cp_profiles"],
        growerProfiles: this.groupDetailData["grower_profiles"],
        generatedContracts: this.groupDetailData["generatedContracts"],
      }
    },
    items() {
      if (this.groupDetailData == null) return []

      const a =  this.groupDetailData['fieldsupply'].map(fs => (
        {
          id: fs['id'],
          field: {
            name: fs['field_name'],
            thumbnail: fs['field_thumbnail'],
            farm: fs['field_farm'],
            selectedFarms: this.selectedFarms
          },
          acres: fs['acreage'],
          crop: {
            value: fs['crop'],
            selectedCrops: this.selectedCrops
          },
          status: {
            value: fs['status'],
            selectedStatuses: this.selectedStatuses
          },
          requestEnrollment: {
            disabled: fs['matched_program'] == null,
            program: fs['matched_program']
          },
          practices: fs['practices'].length,
          practicesDetail: fs['practices'].map(p => p.includes(': ') ? p.split(': ')[1] : p),
          region: US_STATES.find(({ text }) => text == fs['region'])['value'],
          submitDate: null,
          deadline: null
        }
      ))

      return a
    },
    fieldsInGroup() {
      if (this.groupDetailData == null) return []

      return this.groupDetailData['fieldsupply'].map(fs => (
        {
          id: fs['id'],
          field: {
            name: fs['field_name'],
            thumbnail: fs['field_thumbnail']
          },
          acres: fs['acreage'],
          crop: fs['crop'],
          status: fs['status'],
          requestEnrollment: {
            disabled: fs['matched_program'] == null
          },
          group: fs['contract_group'] || null
        }
      ))
    },
    fssCommittedForEnrollment() {
      return this.groupDetailData['fieldsupply']
      .filter(({ status }) => status == LOCKED)
      .map(({ id }) => id)
    }
  },
  methods: {
    handleDataTableFiltering(value, search, item) {
      console.log('all!', value, search, item);
      return true
    },
    toggleShowRollup() {
      this.showRollupTable = !this.showRollupTable
    },
    handleUpdateGroupData(data) {
      this.groupDetailData = data
    },
    handleProfileModificationModalOpen() {
      this.dataForProfileModificationModal = {}
      this.openProfileModificationModal = true
    },
    handleProfileModificationModalClose() {
      this.dataForProfileModificationModal = null
      this.openProfileModificationModal = false
    },
    handleLOIModalOpen(data) {
      this.dataForLOIModal = {
        ...data,
        org: this.org,
      }
      this.openLOIModal = true
    },
    handleLOIModalClose() {
      this.dataForLOIModal = null
      this.openLOIModal = false
    },
    handleManagementSidebarClose(cancelled) {
      if (!cancelled) {
      }

      this.showMangementSidebar = false
    },
    async generateLOI(payload) {
      const appendedPayload = {
        ...payload,
        fss_ids: this.groupDetailData["fieldsupply"].map(({ id }) => id),
        year: this.groupDetailData["year"],
      }

      this.openLOIModal = false
      this.showLoading = true
      FieldsAPI.generateLOI(appendedPayload).then(r => {
        alert("LOI Created!")
        // this.fetchClientProfile()
        // this.showLoading = false
        // this.message = `Letter of Intent for ${this.selectedClient.client.name} (${this.selectedClient.client.id}) has been generated successfully.`
        // this.showSuccessAlert = true
        // setTimeout(() => {
        //   this.showSuccessAlert = false
        // }, 10000)
      })
    },
    getGroupData() {
      this.loading = true

      if (this.org != null && this.org['id'] != null && this.year != null) {
        getGroupDetail({ org_node_id: this.org['id'], year: this.year, group_id: this.$router.currentRoute.params['id'] })
        .then(({ data: { group_data, program_rollup } }) => {
          this.groupDetailData = group_data
          this.groupProgramRollup = program_rollup


          this.fieldsSetForEnrollment = group_data['fieldsupply']
          .filter(({ status }) => status == LOCKED)
          .map(({ id }) => id)

          getGroupEditingDataset({ org_node_id: this.org['id'], year: this.year, })
          .then(({ data }) => {
            this.allFieldSupplySummaries = data["fss"]
            this.allClientProfiles = data["client_profiles"].sort((a, b) =>
              a["poc_email"].localeCompare(b["poc_email"])
            )
            this.loading = false
          })
          .catch(e => {
            this.loading = false
            this.error = e
          })
      })}
    },
  },
  watch: {
    org() {
      this.getGroupData()
    },
    selectedCrops(curr) {
      if (curr.length == 0) this.selectedCrops = this.allUniqueCrops
    },
    selectedFarms(curr) {
      if (curr.length == 0) this.selectedFarms = this.allUniqueFarms
    },
    selectedStatuses(curr) {
      if (curr.length == 0) this.selectedStatuses = this.allStatuses
    },
    allUniqueCrops(curr) {
      if (curr.length > 0) this.selectedCrops = curr
    },
    allUniqueFarms(curr) {
      if (curr.length > 0) this.selectedFarms = curr
    },
    fieldsSetForEnrollment(curr) {
      if (curr != null) {
        const fss = []
        let action = null

        if (curr.length == this.fssCommittedForEnrollment.length) return

        if (curr.length > this.fssCommittedForEnrollment.length) {
          fss.push(...curr.filter(id => !this.fssCommittedForEnrollment.includes(id)))
          action = SET_TO_LOCKED
        }

        if (curr.length < this.fssCommittedForEnrollment.length) {
          fss.push(...this.fssCommittedForEnrollment.filter(id => !curr.includes(id)))
          action = SET_TO_AVAILABLE
        }

        this.modifyingFssState = true

        updateFssStatuses({ fss, action, group_id: this.$router.currentRoute.params['id'] })
        .then(({ data }) => {
          this.handleUpdateGroupData(data)
          this.modifyingFssState = false
        })
        .catch(e => console.log('error!'))
      }
    }
  }
}
</script>

<style>
.tooltip-no-transition {
  transition: none !important;
}
</style>

<style scoped>
h1 {
  color: #000;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
}
.rollup-table {
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: var(--custom-table-height, 210px);
  transition: height 0.25s ease-in-out;
  overflow-y: scroll;
}
.rollup-table-summary {
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #E5E7EB;
  border-top: none;
}
.rollup-table :deep(table),
.rollup-table-summary :deep(table) {
  table-layout: fixed;
}
:deep(:not(.rollup-table):not(.rollup-table-summary) > .v-data-table__wrapper) {
  height: 450px;
  overflow-y: scroll;
}
:deep(.v-data-table-header) {
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 1;
}
.rollup-table :deep(thead th) {
  height: 58px !important;
}
.rollup-table thead th > div > p:first-of-type {
  color: #374151;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.rollup-table thead th > div > p:last-of-type {
  color: #374151;
  font-size: 10px;
  font-style: italic;
  line-height: 14px;
  font-weight: 400;
  white-space: nowrap;
}
.rollup-table :deep(thead th:nth-of-type(1)),
.rollup-table-summary thead th:nth-of-type(1) {
  width: 100px;
}
.rollup-table :deep(thead th:nth-of-type(2)),
.rollup-table-summary thead th:nth-of-type(2) {
  width: 160px;
}
.rollup-table :deep(thead th:nth-of-type(n + 3)),
.rollup-table-summary thead th:nth-of-type(n + 3) {
  max-width: 196px;
  width: 100%;
}
.rollup-table tbody td {
  height: 44px !important;
}
.rollup-table tr td:nth-of-type(2) {
  white-space: nowrap;
}
.rollup-table-summary thead th {
  color: #20292F !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: normal;
  padding: 0 16px;
  height: 64px;
}
.rollup-table-summary thead th:nth-of-type(n+3) {
  font-weight: 700 !important;
}
.thumbnail {
  width: 36px;
  height: 36px;
  object-fit: fill;
}
.capitalize {
  text-transform: capitalize;
}
p {
  color: #000000;
}
.v-btn {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04),
    0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}
.v-btn .v-btn__content > i {
  font-size: 20px;
  margin-top: 2px;
}
.v-btn-toggle > .v-btn {
  border-radius: 8px !important;
  color: #6f767e;
  background: #fcfcfc !important;
}
.v-btn-toggle > .v-btn.v-btn--active {
  color: #1a1d1f;
  background: #f1f5f9 !important;
}
.v-card__title {
  color: #20292f;
  font-size: 24px;
}
.v-card__subtitle {
  color: #6b7280;
  line-height: 20px;
}
:deep(.v-simple-checkbox .v-input--selection-controls__input) {
  margin-right: 0 !important;
}
:deep(.v-simple-checkbox .v-input--selection-controls__ripple) {
  display: none;
}
.v-data-table:not(.rollup-table) :deep(tr > th:nth-of-type(1)),
.v-data-table:not(.rollup-table) :deep(tr > td:nth-of-type(1)),
.v-data-table:not(.rollup-table) :deep(tr > td:nth-of-type(2)) {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.v-chip.v-size--default {
  height: 20px !important;
}
.v-input--switch--inset {
  width: min-content;
  margin: 0;
  padding: 0;
}
.v-input--switch--inset :deep(.v-input__control) {
  width: unset;
  flex: none;
}
.v-input--switch--inset :deep(.v-input__slot) {
  width: min-content;
}
.practices-list {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
}
.field-name {
  width: 132px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: none;
}
.v-data-table:not(.rollup-table):not(.rollup-table-summary) :deep(table) {
  table-layout: fixed;
}
.v-data-table:not(.rollup-table):not(.rollup-table-summary) :deep(th:nth-of-type(1)) {
  width: 64px !important;
}
.v-data-table:not(.rollup-table):not(.rollup-table-summary) :deep(th:nth-of-type(2)) {
  width: 192px;
}
.v-data-table:not(.rollup-table):not(.rollup-table-summary) :deep(th:nth-of-type(3)) {
  width: 104px;
}
.v-data-table:not(.rollup-table):not(.rollup-table-summary) :deep(th:nth-of-type(4)) {
  width: 120px;
}
.v-data-table:not(.rollup-table):not(.rollup-table-summary) :deep(th:nth-of-type(5)) {
  width: 150px;
}
.v-data-table:not(.rollup-table):not(.rollup-table-summary) :deep(th:nth-of-type(6)) {
  width: 195px;
}
.v-data-table:not(.rollup-table):not(.rollup-table-summary) :deep(th:nth-of-type(7)) {
  width: 106px;
}
.v-data-table:not(.rollup-table):not(.rollup-table-summary) :deep(th:nth-of-type(8)) {
  width: 350px;
}
.v-data-table:not(.rollup-table):not(.rollup-table-summary) :deep(th:nth-of-type(9)) {
  width: 130px;
}
.v-data-table:not(.rollup-table):not(.rollup-table-summary) :deep(tr td:nth-of-type(5)) {
  padding: 0 4px;
  text-align: center !important;
}
.v-data-table:not(.rollup-table):not(.rollup-table-summary) :deep(tr td:nth-of-type(5) span) {
  font-size: 12px;
}
.table-overview-acre-ct {
  color: #D22F19;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}
.table-overview-disambig {
  font-size: 10px;
  line-height: 14px;
}
.fss-loading {
  opacity: 0.5;
}
.rollup-loader {
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  z-index: 1;
}
.collapsible-fxn {
  position: relative;
  cursor: pointer;
}
.collapsible-fxn::after {
  position: absolute;
  content: "";
  background: var(--chevron-down-pseudo-src, "");
  transform: rotate(var(--flipped, 0deg));
  transition: transform 0.25s ease-in-out;
  right: 36px;
  bottom: calc(50% - 16px);
  width: 32px;
  height: 32px;
}

.custom-toggler {
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  background: #FFF;
  width: 148px;
  overflow: hidden;
}
.custom-toggler.disabled {
  opacity: 0.5;
}
.custom-toggler.disabled a {
  pointer-events: none;
}
.custom-toggler a {
  color: #374151;
}
.custom-toggler a:first-of-type {
  border-right: 1px solid #E5E7EB;
}
.custom-toggler a:hover {
  opacity: 0.5;
}
.custom-toggler a.selected {
  background: #61AF02;
  color: white;
}

/* v-select overrides */
.v-input.v-select {
  width: 144px;
  height: 40px;
  max-width: unset;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  font-weight: 500;
  border-radius: 8px;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"]) {
  background: #6B7280;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(.v-input__append-inner:nth-last-of-type(2) > div) {
  opacity: 1;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(.v-input__append-inner:last-of-type) {
  display: none;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] p),
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] i),
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] button.mdi-close) {
  color: white;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] p span) {
  padding: 1px 2px;
  color: black;
  background: white;
  border-radius: 2px;
}
.v-input.v-select :deep(div[role="button"]) {
  min-height: 40px;
}
.v-input.v-select :deep(.v-select__slot label) {
  top: 7px;
}
.v-input.v-select :deep(.v-input__append-inner) {
  margin-top: 0px !important;
}
.v-chip.gray {
  background: #F3F4F6 !important;
}
.v-chip.gray :deep(span) {
  color: #374151;
}
.v-chip.green {
  background: #F1FDDF !important;
}
.v-chip.green :deep(span) {
  color: #61B100;
}
</style>
