<template>
  <div>
    <div v-if="selectedFields.length > MAX_DEFAULT_FIELDS">
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >Please select fewer than {{ MAX_DEFAULT_FIELDS }} fields using the
            top navigation tools.</span
          >
        </v-card-text>
      </v-card>
    </div>
    <div v-else-if="selectedFields.length > 0">
      <div id="input-card-container">
        <v-card
          class="my-auto mx-auto"
          v-if="showPracticesView || showSaveView || showSummaryView"
        >
          <v-card-text class="min-step-height" v-if="showPracticesView">
            <v-row class="mx-1 mt-3">
              <div class="text-h5">Add Fertilizer for {{ year }}</div>

              <v-btn class="ml-auto" @click="handleCancelClick()">
                <v-icon>mdi-close</v-icon> Cancel
              </v-btn>

              <v-btn
                class="ml-4"
                color="green lighten-4"
                :disabled="allAppsComplete().length > 0"
                @click="handlePreSave()"
                >Save and Continue</v-btn
              >
            </v-row>

            <v-row class="mx-1 mt-7">
              <v-btn-toggle
                v-model="hasPracticeData"
                readonly
                divided
                dense
                mandatory
                active-class="light-blue lighten-4"
              >
                <v-btn
                  :value="true"
                  class="px-3"
                  @click="handleYesFertilizerClick"
                >
                  Applied Fertilizer <v-icon>mdi-check-bold</v-icon>
                </v-btn>
                <v-btn
                  :value="false"
                  class="px-3"
                  @click="handleNoFertilizerClick"
                >
                  No Fertilizer <v-icon>mdi-close-thick</v-icon>
                </v-btn>
              </v-btn-toggle>

              <v-btn-toggle
                class="ml-5"
                v-model="stackPractices"
                divided
                dense
                active-class="light-blue lighten-4"
                mandatory
              >
                <v-btn :value="true" :disabled="!hasPracticeData" class="px-3"
                  >Stack Practices</v-btn
                >
                <v-btn :value="false" class="px-3">Replace Practices</v-btn>
              </v-btn-toggle>
            </v-row>

            <v-row v-if="applicationData.length > 0">
              <v-col md="12">
                <v-card
                  v-for="(app, index) in applicationData"
                  :key="app.idx"
                  class="mt-3"
                  elevation="6"
                >
                  <v-card-title
                    class="cursor blue-grey lighten-5"
                    @click="app.showAllInfo = !app.showAllInfo"
                  >
                    Application #{{ applicationData.indexOf(app) + 1 }}
                    <v-icon
                      v-if="!app.showAllInfo"
                      @click="app.showAllInfo = true"
                      >mdi-chevron-right</v-icon
                    >
                    <v-icon v-else @click="app.showAllInfo = false"
                      >mdi-chevron-down</v-icon
                    >
                    <AudioRecorder
                      model="fertilizer"
                      :practiceIndex="applicationData.indexOf(app)"
                      @practice-response="handleSpeechToPractice"
                      :year="Number(year)"
                    />
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="ml-auto"
                          :color="
                            isAppComplete(app)
                              ? 'green accent-4'
                              : 'red darken-1'
                          "
                        >
                          {{
                            isAppComplete(app)
                              ? "mdi-check-circle-outline"
                              : "mdi-alert-circle-outline"
                          }}
                        </v-icon> </template
                      ><span>{{
                        isAppComplete(app)
                          ? "Application is good to go!"
                          : "You're missing a few fields, please complete this application to save and continue!"
                      }}</span></v-tooltip
                    >
                    <v-btn
                      class="ml-2"
                      @click.stop="
                        handleRemoveApp(applicationData.indexOf(app))
                      "
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text v-if="app.showAllInfo">
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mt-3 mb-2">
                          <b
                            >On which crop was this applied?<span
                              class="red--text"
                              >*</span
                            ></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Crop"
                            :disabled="
                              cropSelect ? cropSelect.length == 1 : false
                            "
                            :items="cropChoicesFiltered"
                            v-model="app.crop_id"
                            clearable
                          />
                        </div>
                      </v-col>
                      <v-col>
                        <div class="mt-3 mb-2">
                          <b
                            >Application Date<span class="red--text">*</span></b
                          >
                        </div>
                        <div class="input-holder">
                          <v-menu
                            v-model="app.menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="app.date"
                                label="Application Date"
                                dense
                                outlined
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="app.date"
                              :picker-date.sync="app.pickerDate"
                              :min="yearPriorOne + '-01-01'"
                              :max="yearExtendedOne + '-12-31'"
                              @input="app.menu = false"
                              scrollable
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Application Method and Product -->
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7">
                            Describe your application method and product:<span
                              class="red--text"
                              >*</span
                            >
                          </b>
                        </div>
                        <div class="input-holder">
                          <PracticeChoiceDropdown
                            dense
                            outlined
                            hide-details
                            label="Method"
                            :items="methodChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="app.method"
                            choiceName="fertilizer_method"
                            clearable
                          />
                        </div>
                      </v-col>
                      <v-col md="4">
                        <div class="input-holder">
                          <v-select
                            dense
                            outlined
                            hide-details
                            :disabled="app.method ? false : true"
                            :label="app.method ? 'Product' : 'Select a Method'"
                            :items="productsByMethod(app.method)"
                            v-model="app.product"
                            item-text="display_name"
                            item-value="value"
                            @change="calculateNPKforApplication(index)"
                          >
                          </v-select>
                        </div>
                      </v-col>
                      <v-col v-if="app.product == 'other'">
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            label="Other product name"
                            v-model="app.otherProduct"
                          >
                          </v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Application Rate Row -->
                    <v-row align="end">
                      <v-col md="4">
                        <div class="mb-2">
                          <b class="text-h7">
                            What was your application rate?<span
                              class="red--text"
                              >*</span
                            >
                          </b>
                        </div>
                        <div class="input-holder">
                          <v-text-field
                            dense
                            outlined
                            hide-details
                            label="Rate"
                            :rules="[numberValidator]"
                            v-model="app.amount"
                            type="number"
                            @change="calculateNPKforApplication(index)"
                          ></v-text-field>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="input-holder">
                          <b class="text-h7"></b>
                          <v-select
                            dense
                            outlined
                            hide-details
                            label="Rate Units"
                            :items="rateChoices"
                            item-text="display_name"
                            item-value="value"
                            v-model="app.unit"
                            @change="calculateNPKforApplication(index)"
                          ></v-select>
                        </div>
                      </v-col>
                      <v-col>
                        <div>
                          <b class="text-h7">
                            Is this application 4R compliant?<span
                              class="red--text"
                              >*</span
                            >
                          </b>
                          <div class="pt-2">
                            <v-btn
                              rounded
                              outlined
                              @click="app.four_r = true"
                              :input-value="app.four_r === true"
                              >Yes</v-btn
                            >
                            <v-btn
                              class="ml-4"
                              rounded
                              outlined
                              @click="app.four_r = false"
                              :input-value="app.four_r === false"
                              >No</v-btn
                            >
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <div v-if="app.product && app.product == 'other'">
                          <div class="mb-2">
                            <b class="text-h7"> Nutrient ratio </b>
                          </div>
                          <div class="input-holder d-flex">
                            <v-text-field
                              dense
                              outlined
                              hide-details
                              label="N"
                              :rules="[numberValidator]"
                              :min="0"
                              :max="100"
                              v-model="app.manualNPK.N"
                              type="number"
                              @change="calculateNPKforApplication(index)"
                            ></v-text-field>
                            <v-text-field
                              dense
                              outlined
                              hide-details
                              label="P"
                              :rules="[numberValidator]"
                              :min="0"
                              :max="100"
                              v-model="app.manualNPK.P"
                              type="number"
                              @change="calculateNPKforApplication(index)"
                            ></v-text-field>
                            <v-text-field
                              dense
                              outlined
                              hide-details
                              label="K"
                              :rules="[numberValidator]"
                              :min="0"
                              :max="100"
                              v-model="app.manualNPK.K"
                              type="number"
                              @change="calculateNPKforApplication(index)"
                            ></v-text-field>
                            <v-text-field
                              dense
                              outlined
                              hide-details
                              label="S"
                              :rules="[numberValidator]"
                              :min="0"
                              :max="100"
                              v-model="app.manualNPK.S"
                              type="number"
                              @change="calculateNPKforApplication(index)"
                            ></v-text-field>
                          </div>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="d-flex flex-row">
                          <div
                            class="mr-4"
                            v-if="
                              app.product &&
                              app.product == 'other' &&
                              isApplicationLiquid(app)
                            "
                          >
                            <div class="mb-2">
                              <b class="text-h7">Density</b>
                            </div>
                            <v-text-field
                              dense
                              outlined
                              hide-details
                              label="lb/gal"
                              :rules="[numberValidator]"
                              :min="0"
                              :max="25"
                              style="width: 100px"
                              v-model="app.manualDensity"
                              type="number"
                              @change="calculateNPKforApplication(index)"
                            ></v-text-field>
                          </div>
                          <div>
                            <div class="mb-2 text-h7">Nutrient lb/ac:</div>
                            <p class="text-subtitle-1 pt-2">
                              <span class="font-weight-bold">N: </span
                              >{{ app.N }}
                              <span class="font-weight-bold">P: </span
                              >{{ app.P }}
                              <span class="font-weight-bold">K: </span
                              >{{ app.K }}
                              <span class="font-weight-bold">S: </span
                              >{{ app.S }}
                            </p>
                          </div>
                        </div>
                      </v-col>
                      <v-col>
                        <div>
                          <b class="text-h7">
                            Did you use a slow-release fertilizer?<span
                              class="red--text"
                              >*</span
                            >
                          </b>
                          <div class="pt-2">
                            <v-btn
                              rounded
                              outlined
                              @click="app.slow_release = true"
                              :input-value="app.slow_release === true"
                              >Yes</v-btn
                            >
                            <v-btn
                              class="ml-4"
                              rounded
                              outlined
                              @click="app.slow_release = false"
                              :input-value="app.slow_release === false"
                              >No</v-btn
                            >
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Bottom Row -->
                    <v-row>
                      <v-col md="4">
                        <b class="text-h7">
                          Did you use a nitrogen (urease) inhibitor?<span
                            class="red--text"
                            >*</span
                          >
                        </b>
                        <div class="pt-2">
                          <v-btn
                            rounded
                            outlined
                            @click="app.inhibitor = true"
                            :input-value="app.inhibitor === true"
                            >Yes</v-btn
                          >
                          <v-btn
                            class="ml-4"
                            rounded
                            outlined
                            @click="app.inhibitor = false"
                            :input-value="app.inhibitor === false"
                            >No</v-btn
                          >
                        </div>
                      </v-col>
                      <v-col md="4">
                        <b class="text-h7">
                          Did you use a management plan?<span class="red--text"
                            >*</span
                          >
                        </b>
                        <div class="pt-2">
                          <v-btn
                            rounded
                            outlined
                            @click="app.management_plan = true"
                            :input-value="app.management_plan === true"
                            >Yes</v-btn
                          >
                          <v-btn
                            class="ml-4"
                            rounded
                            outlined
                            @click="app.management_plan = false"
                            :input-value="app.management_plan === false"
                            >No</v-btn
                          >
                        </div>
                      </v-col>
                      <v-col md="4">
                        <b class="text-h7">
                          Did you use Variable Rate Technology (VRT)?<span
                            class="red--text"
                            >*</span
                          >
                        </b>
                        <div class="pt-2">
                          <v-btn
                            rounded
                            outlined
                            @click="app.vrt = true"
                            :input-value="app.vrt === true"
                            >Yes</v-btn
                          >
                          <v-btn
                            class="ml-4"
                            rounded
                            outlined
                            @click="app.vrt = false"
                            :input-value="app.vrt === false"
                            >No</v-btn
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-btn class="mt-4" block @click="handleAddEventClick">
                  Add Fertilizer Event<v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="applicationData.length == 0">
              <v-card class="mx-auto mt-4" elevation="0">
                <v-card-text>
                  <div class="text-center">
                    <b class="text-h5 text-bold"
                      >No Fertilizers Applied for {{ this.year }}</b
                    >
                  </div>
                </v-card-text>
              </v-card>
            </v-row>
          </v-card-text>
          <!-- Save Spinner View -->
          <v-card-text class="min-step-height text-center" v-if="showSaveView">
            <v-progress-circular
              class="mt-4"
              :size="100"
              :width="8"
              color="green"
              indeterminate
            >
            </v-progress-circular>
            <div>
              <h3>Saving your fertilizer information</h3>
            </div>
          </v-card-text>
          <!-- Summary View -->
          <v-card-text class="min-step-height" v-if="showSummaryView">
            <div class="d-flex">
              <h3>Fields Updated</h3>
              <v-btn class="ml-auto" @click="handleAddMoreFertilizer">
                Add More Fertilizer
              </v-btn>
            </div>
            <div class="d-flex">
              <v-chip
                class="mx-2"
                v-for="fieldName in summaryFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3 v-if="failedFields.length > 0">Some Fields Failed to Save</h3>
            <div v-if="failedFields.length > 0" class="d-flex">
              <v-chip
                color="red lighten-3"
                class="mx-2"
                v-for="fieldName in failedFields"
                :key="fieldName.idx"
                >{{ fieldName }}</v-chip
              >
            </div>
            <br />
            <h3>Fertilizer Data Submitted</h3>
            <div>
              <v-simple-table class="mx-4 mb-4">
                <thead>
                  <tr>
                    <th v-for="key in summaryKeys" :key="key.idx">
                      {{ key == "crop_id" ? "Crop" : key | cleanSnake }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="data in summaryData" :key="data.idx">
                    <td v-for="key in summaryKeys" :key="key.idx">
                      <span v-if="key == 'crop_id'">{{
                        cropDisplay[data[key].value]
                      }}</span>
                      <span v-else-if="key.length == 1">
                        {{ data[key].value | floatTenth }} lb/ac
                      </span>
                      <span v-else>
                        {{
                          data[key].value
                            ? data[key].value
                            : "None" | cleanSnake
                        }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </v-card-text>
        </v-card>
      </div>
      <!-- Fields, Data Comp, and Add Practices -->
      <v-row
        class="mt-4 mb-2 row-margin-correct"
        v-if="!showPracticesView && !showSaveView"
      >
        <div>
          <span class="text-h5 ml-1"
            >{{ fertSettingsByField.length }} fields</span
          >
          <span class="text-h5 ml-2"
            >({{ dataCompletionForYear }}% Complete)</span
          >
        </div>
        <div class="ml-auto d-flex">
          <div>
            <v-btn
              v-for="y in yearListModified"
              :key="y.idx"
              :color="
                parseInt(y) === parseInt(year) ? 'blue-grey lighten-4' : 'white'
              "
              elevation="0"
              tile
              dense
              class="mx-1 py-1"
              @click="handleSeasonSelection(y)"
            >
              {{ y }}
            </v-btn>
          </div>
          <div class="text-container ml-2">
            <v-text-field
              outlined
              dense
              hide-details
              v-model="tableFilterText"
              label="Filter Fields"
            ></v-text-field>
          </div>
          <div class="ml-2">
            <v-btn
              class="white--text"
              color="green"
              x-large
              :disabled="fieldsInSelection.length === 0"
              @click="handleAddPracticesClick"
              >Add Practices</v-btn
            >
          </div>
        </div>
      </v-row>
      <v-row class="mt-4 mb-2 row-margin-correct" v-else>
        <div>
          <span class="text-button ml-1">Receiving fields</span>
        </div>
      </v-row>

      <!-- Quick Filtering Buttons -->
      <v-row class="mt-4 row-margin-correct">
        <v-btn
          dense
          tile
          :color="cropSelect == null ? 'blue-grey lighten-4' : 'white'"
          @click="handleSelectAll(true, true)"
          class="px-2 mx-2 py-1 text-none letter-spacing-0"
          elevation="0"
        >
          All ({{ fertSettingsByField.length }})
        </v-btn>
        <v-btn
          v-for="sharedCropPlan in historicalPlantings"
          :key="sharedCropPlan.idx"
          dense
          tile
          :color="
            JSON.stringify(cropSelect) ==
            JSON.stringify(sharedCropPlan.crops.split(','))
              ? 'blue-grey lighten-4'
              : 'white'
          "
          @click="handleCropFilterButton(sharedCropPlan)"
          class="px-2 mx-2 py-1 text-none"
          elevation="0"
        >
          {{ mapCropNames(sharedCropPlan.crops) }} ({{
            sharedCropPlan.fieldIds.length
          }})
        </v-btn>
      </v-row>
      <!-- Field Filtering Table -->
      <v-row class="pt-3">
        <v-col>
          <v-card>
            <v-card-text v-if="showSelectedFields">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="three">
                      <div class="d-flex">
                        <v-simple-checkbox
                          color="blue"
                          v-model="selectAllBox"
                          @input="handleSelectAll($event)"
                        ></v-simple-checkbox>
                      </div>
                    </th>
                    <th class="ten">Name</th>
                    <th class="three">Farm</th>
                    <th class="three">Locked</th>
                    <th class="three">Crops</th>
                    <th>Applications</th>
                    <th class="three">
                      <div>Copy</div>
                    </th>
                    <th v-if="showPracticesView">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="field in fertSettingsbyFieldFiltered"
                    :key="field.idx"
                  >
                    <td>
                      <v-simple-checkbox
                        color="blue"
                        :value="
                          fieldsInSelection.includes(parseInt(field.fieldId))
                        "
                        :disabled="
                          disableCheckbox(field.noYearData) || field.fieldLocked
                        "
                        @input="fieldChecked(field, $event)"
                      ></v-simple-checkbox>
                    </td>
                    <td>{{ field.fieldName }}</td>
                    <td class="overflow-hidden">{{ field.farmName }}</td>
                    <td>
                      <v-icon
                        size="20"
                        v-if="field.fieldLocked"
                        :color="field.fieldLocked ? 'red' : 'green'"
                      >
                        {{ field.fieldLocked ? "mdi-lock" : "mdi-lock-open" }}
                      </v-icon>
                    </td>
                    <td>
                      <div v-for="crop in field.cropNames" :key="crop">
                        {{ crop }}
                      </div>
                    </td>
                    <!-- Fert Applications Cell -->
                    <td class="py-2" v-if="!field.noYearData">
                      <v-row
                        class="mx-0"
                        :class="
                          field.fertInfo.indexOf(fertSingle) !=
                          field.fertInfo.length - 1
                            ? 'row-bottom'
                            : ''
                        "
                        dense
                        v-for="fertSingle in field.fertInfo.sort(
                          (a, b) => a.crop_id.value - b.crop_id.value
                        )"
                        :key="fertSingle.idx"
                      >
                        <v-col md="2">
                          <span class="d-flex flex-row">
                            <v-lazy>
                              <v-tooltip
                                v-if="
                                  fertSingle.date.source == 'shapefile' &&
                                  fertSingle.date.value !== null
                                "
                                top
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <img
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mr-1 my-auto machine-icon"
                                    :src="machineIcon"
                                  />
                                </template>
                                <span>
                                  Data is from a machine source (such as John
                                  Deere) or shapefile import.
                                </span>
                              </v-tooltip>
                            </v-lazy>
                            {{ fertSingle.date.value }}
                          </span>
                        </v-col>
                        <v-col md="3">
                          <span
                            :class="
                              fertSingle.product.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              fertSingle.product.value
                                ? fertSingle.product.value
                                : " No Product" | cleanSnake
                            }}
                          </span>
                        </v-col>
                        <v-col md="2">
                          <span
                            :class="
                              fertSingle.amount.value !== null
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              fertSingle.amount.value !== null
                                ? fertSingle.amount.value
                                : "No Amount" | cleanSnake
                            }}
                          </span>
                          <span
                            :class="
                              fertSingle.unit.value
                                ? 'black--text'
                                : 'red--text font-weight-bold'
                            "
                          >
                            {{
                              fertSingle.unit.value
                                ? mapOldUnits(fertSingle.unit.value)
                                : "Missing Units"
                            }}
                          </span>
                        </v-col>
                        <v-col md="3">
                          <div class="d-flex flex-row">
                            <span
                              style="width: 40px"
                              :class="{
                                'red--text': fertSingle.N.value === null,
                              }"
                            >
                              {{
                                fertSingle.N.value !== null
                                  ? parseFloat(fertSingle.N.value).toFixed(1)
                                  : "None"
                              }}
                            </span>
                            <span
                              style="width: 40px"
                              :class="{
                                'red--text': fertSingle.P.value === null,
                              }"
                            >
                              {{
                                fertSingle.P.value !== null
                                  ? parseFloat(fertSingle.P.value).toFixed(1)
                                  : "None"
                              }}
                            </span>
                            <span
                              style="width: 40px"
                              :class="{
                                'red--text': fertSingle.K.value === null,
                              }"
                            >
                              {{
                                fertSingle.K.value !== null
                                  ? parseFloat(fertSingle.K.value).toFixed(1)
                                  : "None"
                              }}
                            </span>
                            <span
                              style="width: 40px"
                              :class="{
                                'red--text': fertSingle.S.value === null,
                              }"
                            >
                              {{
                                fertSingle.S.value !== null
                                  ? parseFloat(fertSingle.S.value).toFixed(1)
                                  : "None"
                              }}
                            </span>
                            <span>N-P-K-S lb/ac</span>
                          </div>
                        </v-col>
                        <v-col md="2">
                          <!-- Data Complete -->
                          <div
                            class="d-flex"
                            v-if="fertilizerComplete(fertSingle)"
                          >
                            <v-lazy>
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="success"
                                    >mdi-check-circle</v-icon
                                  ></template
                                ><span
                                  >You've filled out enough information for Arva
                                  data models to run. Thank you!</span
                                ></v-tooltip
                              >
                            </v-lazy>
                            <span class="ml-2">Data Complete</span>
                          </div>
                          <!-- Needs Confirmation -->
                          <div
                            class="d-flex"
                            v-else-if="fertilizerNeedsConfirm(fertSingle)"
                          >
                            <v-lazy>
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    color="yellow"
                                    >mdi-alert-rhombus</v-icon
                                  ></template
                                ><span
                                  >This information is coming from a public
                                  source. Please include this field when setting
                                  information to confirm it's data!</span
                                ></v-tooltip
                              >
                            </v-lazy>
                            <span
                              class="ml-2 cursor blue--text lighten-1 text-bold"
                              @click="handleConfirm(field)"
                              >Confirm Data</span
                            >
                          </div>
                          <!-- Missing Data -->
                          <div
                            class="d-flex"
                            v-else-if="fertilizerMissingData(fertSingle)"
                          >
                            <v-lazy>
                              <v-tooltip top
                                ><template v-slot:activator="{ on, attrs }"
                                  ><v-icon v-bind="attrs" v-on="on" color="red"
                                    >mdi-alert-circle</v-icon
                                  ></template
                                ><span
                                  >This field is missing data. Please fill in
                                  its data using the wizard!</span
                                ></v-tooltip
                              >
                            </v-lazy>
                            <span class="ml-2">Missing Data</span>
                          </div>
                        </v-col>
                      </v-row>
                    </td>
                    <td class="py-2" v-else>
                      <div @click="handleShowRedirect(field)">
                        <v-tooltip top
                          ><template v-slot:activator="{ on, attrs }"
                            ><v-icon v-bind="attrs" v-on="on" color="red"
                              >mdi-alert-circle</v-icon
                            ></template
                          ><span
                            >This field doesn't have data for this year. Add
                            another year in the data spreadsheet or upload data
                            from this year.</span
                          ></v-tooltip
                        ><span class="ml-2 cursor red--text text-bold"
                          >No Data for Year</span
                        >
                      </div>
                    </td>
                    <!-- Copy Cell -->
                    <td class="cursor" @click="handleCopy(field)">
                      <span class="blue--text lighten-1 text-bold">Copy</span>
                    </td>
                    <!-- Status Column -->
                    <td v-if="showPracticesView">
                      <span
                        class="py-2"
                        v-if="
                          !field.noYearData && allAppsComplete().length === 0
                        "
                      >
                        <v-row
                          class="mx-0"
                          :class="
                            field.fertInfo.indexOf(fertSingle) !=
                            field.fertInfo.length - 1
                              ? 'row-bottom'
                              : ''
                          "
                          dense
                          v-for="fertSingle in field.fertInfo"
                          :key="fertSingle.idx"
                        >
                          <v-lazy>
                            <v-tooltip
                              v-if="
                                stackPractices && isAnyDuplicate(fertSingle)
                              "
                              top
                              max-width="300"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="orange"
                                  >mdi-alert</v-icon
                                >
                              </template>
                              <span
                                >Can not stack duplicate practice on this
                                field.</span
                              >
                            </v-tooltip>
                          </v-lazy>
                          <v-lazy>
                            <v-tooltip
                              v-if="isUntargetedCrop(fertSingle)"
                              top
                              max-width="300"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" color="orange"
                                  >mdi-selection-ellipse-remove</v-icon
                                >
                              </template>
                              <span
                                >No practice data is currently being added for
                                {{
                                  cropDisplay[fertSingle.crop_id.value]
                                }}.</span
                              >
                            </v-tooltip>
                          </v-lazy>
                        </v-row>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Incomplete Application Modal -->
      <IncompleteFertAppModal
        v-if="showIncompleteModal"
        :incompleteApplications="incompleteApplications"
        dataCategory="application"
        @close-template-modal="showIncompleteModal = false"
      />

      <!-- No Fields Selected Alert -->
      <AlertErrorModal
        v-if="alertErrorModalOpen"
        titleText="No Fields Selected"
        :errorMessage="[
          'You currently have no fields selected to save management practices for. Please select a field to continue.',
        ]"
        @close-modal="alertErrorModalOpen = false"
      />

      <!-- Wizard Confirm Modal -->
      <WizardConfirmModal
        v-if="showConfirmModal"
        :confirmationField="confirmationField"
        :confirmationData="confirmationApplications"
        :wantedKeys="['date', 'product', 'amount', 'unit']"
        :year="year"
        :loadState="loadState"
        @close-template-modal="handleCloseConfirm"
        @confirm-data="handleModalConfirm"
      />

      <ConfirmModal
        v-if="showSaveConfirmModal"
        titleText="Confirm Practice Replacement"
        :confirmText="saveConfirmText"
        @confirm="handleSave"
        @close-modal="showSaveConfirmModal = false"
      />

      <!-- Redirect -->
      <WizardRedirectModal
        v-if="showRedirectModal"
        :redirectField="redirectField"
        :year="year"
        @close-template-modal="handleCloseRedirect"
        @redirect-confirm="handleRedirectConfirm"
      />
    </div>
    <div v-else>
      <v-card elevation="0">
        <v-card-text class="text-center">
          <span class="text-h4"
            >No Fields Selected. Please select a field to apply management
            practices.</span
          >
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import _ from "lodash"
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { Defaults, Fertilizer, Organization } from "@/store/modules"
import { CROP_DISPLAY_NAME, CROP_KEY, CROP_ID_KEY } from "@/constants"
import { MAX_DEFAULT_FIELDS } from "@/constants/defaults"
import IncompleteFertAppModal from "@/components/modals/IncompleteFertAppModal"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import WizardConfirmModal from "@/components/modals/WizardConfirmModal"
import WizardRedirectModal from "@/components/modals/WizardRedirectModal"
import ConfirmModal from "@/components/modals/ConfirmModal"
import { numberValidator } from "@/utility"
import { mapPayloadKeysToUserInput, dupeCheck } from "./utils"
import PracticeChoiceDropdown from "@/components/dropdowns/PracticeChoiceDropdown"
import AudioRecorder from "./AudioRecorder.vue"
import Vue from "vue"
import machineIcon from "@/assets/images/star-04.svg"

export default {
  name: "NewFertilizerWizard",
  props: {
    selectedFields: { type: Array },
    year: { type: String },
    yearList: { type: Array },
    dataCompletionMaster: { type: Object },
  },
  components: {
    AlertErrorModal,
    IncompleteFertAppModal,
    WizardConfirmModal,
    WizardRedirectModal,
    PracticeChoiceDropdown,
    ConfirmModal,
    AudioRecorder,
  },
  data() {
    return {
      // constants
      cropDisplay: CROP_DISPLAY_NAME,
      // data values
      applicationData: [],
      stackPractices: true,
      // choices
      cropChoices: Object.values(CROP_KEY),
      // table related
      showSelectedFields: true,
      fieldsInSelection: [],
      filterCrops: [],
      filterCropsReflection: [],
      tableFilterText: "",
      selectAllBox: true,
      numberValidator,
      MAX_DEFAULT_FIELDS: MAX_DEFAULT_FIELDS,
      // full validation for application completeness
      showIncompleteModal: false,
      incompleteApplications: null,
      alertErrorModalOpen: false,
      // confirmation
      confirmationField: null,
      confirmationApplications: null,
      showConfirmModal: false,
      showSaveConfirmModal: false,
      loadState: null,
      // redirection
      showRedirectModal: false,
      redirectField: null,
      // select crop type UX, disables selecting new fields when selecting by crop tile
      cropSelect: null,
      //   new UI
      showPracticesView: false,
      showSaveView: false,
      showSummaryView: false,
      summaryFields: null,
      failedFields: [],
      summaryKeys: null,
      summaryData: null,
      machineIcon,
    }
  },
  computed: {
    ...mapGetters({
      fertilizerOptions: Fertilizer.Getters.getFertilizers,
      recentWizardData: Defaults.Getters.getRecentWizardData,
      practiceChoices: Defaults.Getters.getPracticeChoices,
    }),
    ...mapState({
      fertSettings: state => state.Defaults.newFieldSettings,
    }),
    methodChoices() {
      return this.practiceChoices["fertilizer_method"]
    },
    rateChoices() {
      return this.practiceChoices["fertilizer_units"]
    },
    cropChoicesFiltered() {
      if (this.cropSelect != null) {
        const filteredCropList = Object.entries(CROP_KEY)
          .filter(o => {
            if (this.cropSelect.length > 1) {
              return this.cropSelect.includes(o[0])
            } else {
              return true
            }
          })
          .map(list => list[1])
        return filteredCropList
      } else {
        const selectedOpSettings = this.fertSettingsByField.filter(field =>
          this.fieldsInSelection.includes(parseInt(field.fieldId))
        )
        const selectedCrops = new Set(
          selectedOpSettings.flatMap(field => field.crops)
        )
        const selectedCropNames = Array.from(selectedCrops).map(
          c => CROP_KEY[c]
        )
        return selectedCropNames.filter(i => i != undefined)
      }
    },
    hasPracticeData() {
      return this.applicationData.length > 0 ? true : false
    },
    dataCompletionForYear() {
      if (!this.year || this.selectedFields.length == 0) {
        return 0
      }
      let yearDataComps = this.dataCompletionMaster["Fertilizer"].map(o => {
        if ("by_year" in o.data_comp) {
          let temp = o.data_comp.by_year.find(
            yearObj => yearObj.year == this.year
          )
          if (temp) {
            return temp.avg_for_year
          }
        } else {
          return 0
        }
      })
      let average = array => array.reduce((a, b) => a + b) / array.length
      if (yearDataComps.length > 0) {
        return Math.ceil(average(yearDataComps))
      } else {
        return 0
      }
    },
    fieldLocks() {
      let fieldLocks = {}
      for (const field of this.fertSettingsByField) {
        fieldLocks[field.fieldId] = field.fieldLocked
      }
      return fieldLocks
    },
    saveConfirmText() {
      let countText
      if (this.applicationData.length) {
        countText =
          `${this.applicationData.length} fertilizer application` +
          (this.applicationData.length > 1 ? "s" : "")
      } else {
        countText = `No Fertilizer Applied`
      }
      return `
        Are you sure you want to replace practices on the ${this.fieldsInSelection.length} selected fields for ${this.year}?
        <br /><br />
        Existing applications will be removed and replaced with ${countText}.`
    },
    fertSettingsByField() {
      let fertDict = {}
      let yearInConsderation = this.year
      Object.entries(this.fertSettings).forEach(fertDefault => {
        const fieldKey = fertDefault[0]

        const selField = this.selectedFields.find(field => field.id == fieldKey)
        if (!selField) {
          return
        }
        fertDict[fieldKey] = {}
        const fieldSettingObj = fertDefault[1]
        const idSettings = {
          fieldId: fieldKey,
          fieldName: selField.name,
          farmName: selField.farm.name,
          fieldImg: selField.boundary_image,
          fertId: fieldSettingObj.fertilizer_id,
        }
        const hasYearData = fieldSettingObj.year_data.some(
          y => y.year == yearInConsderation
        )

        fieldSettingObj.year_data.forEach(yearData => {
          if (!hasYearData) {
            fertDict[fieldKey] = idSettings
            fertDict[fieldKey]["crops"] = ["No Data"]
            fertDict[fieldKey]["cropNames"] = ["No Data"]
            fertDict[fieldKey]["noYearData"] = true
          } else if (yearData.year == yearInConsderation) {
            const cultivations = yearData.cultivations.sort(
              (a, b) => a.arva_crop_id - b.arva_crop_id
            )
            const hasFertAppsArray = "fert_applications" in cultivations[0]
            if (!hasFertAppsArray) {
              fertDict[fieldKey] = idSettings
              fertDict[fieldKey]["crops"] = ["No Data"]
              fertDict[fieldKey]["cropNames"] = ["No Data"]
              fertDict[fieldKey]["noYearData"] = true
            } else {
              fertDict[fieldKey] = idSettings
              fertDict[fieldKey]["crops"] = []
              fertDict[fieldKey]["cropNames"] = []
              fertDict[fieldKey]["cropSearch"] = ""
              fertDict[fieldKey]["plantings"] = []
              fertDict[fieldKey]["fertInfo"] = []

              fertDict[fieldKey]["isSelected"] =
                this.fieldsInSelection.includes(parseInt(fieldKey))
                  ? true
                  : false

              cultivations.forEach(cultivation => {
                fertDict[fieldKey]["crops"].push(cultivation.arva_crop_id)
                fertDict[fieldKey]["cropNames"].push(
                  CROP_DISPLAY_NAME[cultivation.arva_crop_id]
                )
                fertDict[fieldKey]["cropSearch"] =
                  fertDict[fieldKey]["cropSearch"] +
                  CROP_DISPLAY_NAME[cultivation.arva_crop_id]
                fertDict[fieldKey]["plantings"] = fertDict[fieldKey][
                  "plantings"
                ].concat(cultivation.plantings)
                cultivation.fert_applications.forEach(fertApp =>
                  fertDict[fieldKey]["fertInfo"].push(fertApp)
                )

                fertDict[fieldKey]["crops"].sort()
                fertDict[fieldKey]["cropNames"].sort()
                fertDict[fieldKey]["fieldLocked"] = cultivation.year_data_locked
                fertDict[fieldKey]["noYearData"] = false
              })
            }
          }
        })
      })
      return Object.values(fertDict)
    },
    fertSettingsbyFieldFiltered() {
      console.log("fertSettingsbyFieldFiltered")
      let arrayToReturn = _.cloneDeep(this.fertSettingsByField)

      if (this.tableFilterText) {
        const searchString = this.tableFilterText.toLocaleLowerCase()
        arrayToReturn = arrayToReturn.filter(field =>
          field.fieldName.toLowerCase().includes(searchString)
        )
      }

      if (this.cropSelect) {
        arrayToReturn = arrayToReturn.filter(field => {
          const found = this.cropSelect.every(
            c => field.crops.indexOf(parseInt(c)) >= 0
          )
          return found
        })
      }

      const sortColumns = ["fieldName"]
      const sortDirections = ["desc", "asc"]

      return _.orderBy(arrayToReturn, sortColumns, sortDirections)
    },
    historicalPlantings() {
      const plantingsGrouped = _(this.fertSettingsByField)
        .groupBy("crops")
        .map((items, crops) => ({
          crops: crops,
          fieldIds: _.map(items, "fieldId"),
          fieldNames: _.map(items, "fieldName"),
          noYearData: _.map(items, "noYearData")[0],
        }))
        .value()
      return plantingsGrouped
    },
    filterCropList() {
      return [
        ...new Set(this.fertSettingsByField.map(f => f.cropNames.join(", "))),
      ]
    },
    yearListModified() {
      return this.yearList.filter(o => !isNaN(o))
    },
    yearPriorOne() {
      return String(Number(this.year) - 1)
    },
    yearExtendedOne() {
      return String(Number(this.year) + 1)
    },
  },
  methods: {
    ...mapActions({
      updateYear: Organization.Actions.updateYear,
      updateFertilizer: Defaults.Actions.updateFertilizer,
    }),
    ...mapMutations({
      setRecentWizardData: Defaults.Mutations.setRecentWizardData,
    }),
    isApplicationLiquid(app) {
      return app.unit?.includes("gal")
    },
    calculateNPKforApplication(index) {
      const app = this.applicationData[index]

      if (!app.amount || !app.product || !app.unit) {
        app.N = null
        app.P = null
        app.K = null
        app.S = null
        return
      }

      if (app.product == "other") {
        // "Other" only appears for solid applications, so gallon conversion is not needed (or available)
        if (
          app.manualNPK.N == null ||
          app.manualNPK.P == null ||
          app.manualNPK.K == null ||
          app.manualNPK.S == null
        ) {
          app.N = null
          app.P = null
          app.K = null
          app.S = null
          return
        }

        let conversionLbs = 1
        if (this.isApplicationLiquid(app)) {
          if (app.manualDensity == null) {
            app.N = null
            app.P = null
            app.K = null
            app.S = null
            return
          } else {
            conversionLbs = app.manualDensity
          }
        }

        // to lb/ac
        app.N = ((app.manualNPK.N * app.amount * conversionLbs) / 100).toFixed(
          3
        )
        app.P = ((app.manualNPK.P * app.amount * conversionLbs) / 100).toFixed(
          3
        )
        app.K = ((app.manualNPK.K * app.amount * conversionLbs) / 100).toFixed(
          3
        )
        app.S = ((app.manualNPK.S * app.amount * conversionLbs) / 100).toFixed(
          3
        )
      } else if (app.product == "none") {
        app.N = 0
        app.P = 0
        app.K = 0
        app.S = 0
      } else {
        const fertGettingSet = this.fertilizerOptions.find(
          f => f.fert == app.product
        )
        const chemistryVals = { N: 0, P: 0, K: 0, S: 0 }
        let conversionLbs = 1

        if (this.isApplicationLiquid(app)) {
          conversionLbs = fertGettingSet.lbs_per_gallon
        } else if (app.unit == "ton/ac") {
          conversionLbs = 2000
        }

        chemistryVals["N"] = fertGettingSet.pct_n * app.amount * conversionLbs
        chemistryVals["P"] =
          fertGettingSet.pct_p2o5 * app.amount * conversionLbs
        chemistryVals["K"] = fertGettingSet.pct_k2o * app.amount * conversionLbs
        chemistryVals["S"] = fertGettingSet.pct_s * app.amount * conversionLbs

        app.N = chemistryVals["N"].toFixed(3)
        app.P = chemistryVals["P"].toFixed(3)
        app.K = chemistryVals["K"].toFixed(3)
        app.S = chemistryVals["S"].toFixed(3)
      }
    },
    handleCopy(field) {
      this.cropSelect = null
      const copiedData = this.extractFertData(field)
      for (const practice of copiedData) {
        if (!this.cropChoicesFiltered.includes(practice.crop_id)) {
          practice.crop_id = null
        }
      }
      this.applicationData = copiedData

      this.showSummaryView = false
      this.showPracticesView = true

      const myElement = document.getElementById("input-card-container")
      myElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      })
    },
    extractFertData(field) {
      let extractedData = []
      for (const fert of field.fertInfo) {
        extractedData.push({
          N: fert.N.value,
          P: fert.P.value,
          K: fert.K.value,
          S: fert.S.value,
          ammonium_pct: fert.ammonium_pct ? fert.ammonium_pct.value : null,
          amount: fert.amount.value,
          cost: fert.cost.value,
          crop_id: CROP_KEY[fert.crop_id.value],
          date: fert.date.value,
          inhibitor: fert.inhibitor.value,
          method: fert.method.value,
          product: fert.product.value,
          slow_release: fert.slow_release.value,
          four_r: fert.four_r ? fert.four_r.value : null,
          unit: fert.unit.value,
          vrt: fert.vrt.value,
          management_plan: fert.management_plan.value,
          showAllInfo: true,
          menu: false,
          pickerDate: this.year + "-01",
          manualNPK: { N: null, P: null, K: null, S: null },
        })
      }
      return extractedData
    },
    handleCancelClick() {
      this.showSaveView = false
      this.showSummaryView = false
      this.showPracticesView = false
      this.applicationData = []
    },
    isAppComplete(app) {
      const complete =
        app.crop_id &&
        app.method &&
        app.product &&
        (app.amount !== null || app.four_r != null) &&
        (app.unit || app.four_r != null) &&
        app.date &&
        app.slow_release != null &&
        app.vrt != null &&
        app.management_plan != null &&
        app.inhibitor != null &&
        app.N != null &&
        app.P != null &&
        app.K != null &&
        app.S != null
      return complete
    },
    allAppsComplete() {
      if (!this.applicationData) return [0]
      const appCompleteness = this.applicationData.map(fertApp =>
        this.isAppComplete(fertApp)
      )
      const appsMissing = appCompleteness.map((value, index) =>
        value == null || value == false ? index : ""
      )
      const indexOfMissing = appsMissing.filter(String)
      return indexOfMissing
    },
    handleRemoveApp(appIndex) {
      this.applicationData.splice(appIndex, 1)
    },
    isAnyDuplicate(existingFertApp) {
      for (const newFertApp of this.applicationData) {
        if (
          this.isFertDuplicate(
            existingFertApp,
            mapPayloadKeysToUserInput(newFertApp)
          )
        ) {
          return true
        }
      }
      return false
    },
    isUntargetedCrop(fertApp) {
      if (this.applicationData.length == 0) {
        return false
      }
      const targetedCrops = this.applicationData.map(
        fert => CROP_ID_KEY[fert.crop_id]
      )
      return (
        fertApp.crop_id.value && !targetedCrops.includes(fertApp.crop_id.value)
      )
    },
    isFertDuplicate(fertApp1, fertApp2) {
      const duplicateItems = [
        dupeCheck(fertApp1.N.value, fertApp2.N.value),
        dupeCheck(fertApp1.P.value, fertApp2.P.value),
        dupeCheck(fertApp1.K.value, fertApp2.K.value),
        dupeCheck(fertApp1.S.value, fertApp2.S.value),
        dupeCheck(fertApp1.amount.value, fertApp2.amount.value),
        fertApp1.unit.value == fertApp2.unit.value,
        fertApp1.method.value == fertApp2.method.value,
        fertApp1.product.value == fertApp2.product.value,
        fertApp1.vrt.value == fertApp2.vrt.value,
        fertApp1.inhibitor.value == fertApp2.inhibitor.value,
        fertApp1.slow_release.value == fertApp2.slow_release.value,
        fertApp1.four_r?.value == fertApp2.four_r?.value,
        fertApp1.management_plan.value == fertApp2.management_plan.value,
      ]

      const numDuplicateItems = duplicateItems.filter(Boolean).length

      return (
        fertApp1.crop_id.value == fertApp2.crop_id.value &&
        fertApp1.date.value == fertApp2.date.value &&
        numDuplicateItems >= duplicateItems.length - 2 // try prohibiting > 2 differences
      )
    },
    isFertNone(fertApp) {
      return (
        (fertApp.amount.value == 0 || fertApp.amount.value == null) &&
        (fertApp.unit.value == "none" || fertApp.unit.value == null) &&
        (fertApp.product.value == "none" || fertApp.product.value == null) &&
        (fertApp.method.value == "none" || fertApp.method.value == null) &&
        fertApp.four_r?.value == null
      )
    },
    mergeFieldPayload(field, fertPayload) {
      if (this.applicationData.length == 0 || !this.stackPractices) {
        return fertPayload
      }
      let fieldFertData = field.fertInfo.filter(fert => !this.isFertNone(fert))

      // maintain the source keys of existing ferts, while checking values for duplicates w/ mapPayloadKeys
      let nonDuplicateApps = fieldFertData.filter(
        existingFert =>
          !fertPayload.some(newFert =>
            this.isFertDuplicate(
              mapPayloadKeysToUserInput(existingFert),
              newFert
            )
          )
      )

      const mergedPayload = fertPayload.concat(nonDuplicateApps)
      return mergedPayload
    },
    handlePreSave() {
      if (this.stackPractices) {
        this.handleSave()
      } else {
        this.showSaveConfirmModal = true
      }
    },
    async handleSave() {
      this.showSaveConfirmModal = false

      if (this.fieldsInSelection.length == 0) {
        this.alertErrorModalOpen = true
        return
      }

      const appCompleteness = this.applicationData.map(fertApp =>
        this.isAppComplete(fertApp)
      )
      const appsMissing = appCompleteness.map((value, index) =>
        value == null ? index : ""
      )
      const indexOfMissing = appsMissing.filter(String)

      // find which fert applications are missing info
      if (indexOfMissing.length > 0) {
        this.incompleteApplications = indexOfMissing
        this.showIncompleteModal = true
        return
      } else {
        // save fertilizer to db
        let dataClone = _.cloneDeep(this.applicationData)
        let fertApplicationPayload = null
        if (this.applicationData.length > 0) {
          fertApplicationPayload = dataClone.map(fertApp => {
            // remove showAllInfo and menu to prevent issues with replace_existing in the backend
            delete fertApp["showAllInfo"]
            delete fertApp["menu"]
            delete fertApp["pickerDate"]
            delete fertApp["manualNPK"]
            delete fertApp["manualDensity"]

            if (fertApp["product"] == "other" && fertApp["otherProduct"]) {
              fertApp["product"] = fertApp["otherProduct"]
            }
            delete fertApp["otherProduct"]

            fertApp = mapPayloadKeysToUserInput(fertApp)

            return fertApp
          })
        } else {
          const fieldsBeingSaved = this.fertSettingsbyFieldFiltered.filter(f =>
            this.fieldsInSelection.includes(Number(f.fieldId))
          )
          const cropsBeingSaved = fieldsBeingSaved
            .map(f => f.crops)
            .reduce((acc, arr) => acc.concat(arr), [])
            .filter((item, index, self) => self.indexOf(item) === index)
          fertApplicationPayload = []
          cropsBeingSaved.forEach(cropId => {
            fertApplicationPayload.push({
              N: { value: 0, source: "User Input" },
              P: { value: 0, source: "User Input" },
              K: { value: 0, source: "User Input" },
              S: { value: 0, source: "User Input" },
              ammonium_pct: { value: 0, source: "User Input" },
              amount: { value: 0, source: "User Input" },
              cost: { value: null, source: "User Input" },
              crop_id: { value: cropId, source: "User Input" },
              date: { value: this.year + "-01-01", source: "User Input" },
              inhibitor: { value: false, source: "User Input" },
              method: { value: "none", source: "User Input" },
              product: { value: "none", source: "User Input" },
              slow_release: { value: false, source: "User Input" },
              unit: { value: "none", source: "User Input" },
              vrt: { value: false, source: "User Input" },
              management_plan: { value: false, source: "User Input" },
            })
          })
        }

        this.showPracticesView = false
        this.showSaveView = true

        let fertilizerUpdates = []
        this.fertSettingsbyFieldFiltered.forEach(field => {
          if (this.fieldsInSelection.includes(parseInt(field.fieldId))) {
            if (!field.noYearData && !field.fieldLocked) {
              fertilizerUpdates.push({
                fieldId: field.fieldId,
                promise: this.updateFertilizer({
                  fieldId: field.fieldId,
                  year: this.year,
                  fertApplications: this.mergeFieldPayload(
                    field,
                    fertApplicationPayload
                  ),
                }),
              })
            } else {
              console.log("no year data for ", field)
            }
          }
          return Promise.resolve()
        })
        const results = await Promise.allSettled(
          fertilizerUpdates.map(u => u.promise)
        )

        const failedFields = fertilizerUpdates
          .filter((item, index) => results[index].status === "rejected")
          .map(item => item.fieldId)
        const successFieldNames = this.fertSettingsbyFieldFiltered
          .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
          .filter(f => !failedFields.includes(f.fieldId))
          .map(f => f.fieldName)
        const failedFieldNames = this.fertSettingsbyFieldFiltered
          .filter(f => this.fieldsInSelection.includes(parseInt(f.fieldId)))
          .filter(f => failedFields.includes(f.fieldId))
          .map(f => f.fieldName)

        this.$emit("update-data-complete")

        const fertWantedKeys = [
          "crop_id",
          "date",
          "product",
          "amount",
          "unit",
          "inhibitor",
          "management_plan",
          "slow_release",
          "vrt",
          "four_r",
          "N",
          "P",
          "K",
          "S",
        ]
        this.setRecentWizardData({
          wizard: "fertilizer",
          data: this.applicationData,
        })

        this.summaryKeys = fertWantedKeys
        this.summaryData = fertApplicationPayload
        this.summaryFields = successFieldNames
        this.failedFields = failedFieldNames
        this.showSaveView = false
        this.showSummaryView = true
        this.stackPractices = true
        const cropValue =
          this.cropSelect != null && this.cropSelect.length == 1
            ? CROP_KEY[parseInt(this.cropSelect[0])]
            : null
        this.applicationData = [this._blankApplicationData(cropValue)]
      }
    },
    handleShowRedirect(field) {
      this.redirectField = field
      this.showRedirectModal = true
    },
    handleRedirectConfirm() {
      this.$router.push(`/data-spreadsheet/${this.redirectField.fieldId}`)
    },
    productsByMethod(method) {
      const otherNoneOptions = [
        { display_name: "Other", value: "other" },
        { display_name: "None", value: "none" },
      ]
      if (
        method === "broadcast" ||
        method === "incorporated" ||
        method === "strip till" ||
        method === "banded"
      ) {
        const dryOptions = this.fertilizerOptions.filter(
          f => f.fert_application == "dry"
        )
        return dryOptions.concat(otherNoneOptions)
      }
      if (
        method &&
        ["injected", "sprayed", "fertigation", "foliar"].includes(
          method.toLowerCase()
        )
      ) {
        const liquidOptions = this.fertilizerOptions.filter(
          f => f.fert_application == "liquid"
        )
        return liquidOptions.concat(otherNoneOptions)
      }
      if (method === "manure") {
        const manureOptions = this.fertilizerOptions.filter(
          f => f.fert_application == "manure"
        )
        return manureOptions.concat(otherNoneOptions)
      }
      return otherNoneOptions
    },
    fertilizerComplete(fert) {
      if (!fert) return null
      return (
        fert.date.source == "User Input" &&
        fert.date.value != null &&
        fert.unit.source == "User Input" &&
        fert.unit.value != null &&
        fert.amount.source == "User Input" &&
        fert.amount.value !== null &&
        fert.method.source == "User Input" &&
        fert.method.value != null &&
        fert.product.source == "User Input" &&
        fert.product.value != null &&
        fert.inhibitor.source == "User Input" &&
        fert.inhibitor.value != null &&
        fert.vrt.source == "User Input" &&
        fert.vrt.value != null &&
        fert.management_plan.source == "User Input" &&
        fert.management_plan.value != null &&
        fert.N.source == "User Input" &&
        fert.N.value != null &&
        fert.P.source == "User Input" &&
        fert.P.value != null &&
        fert.K.source == "User Input" &&
        fert.K.value != null &&
        fert.S.source == "User Input" &&
        fert.S.value != null
      )
    },
    fertilizerNeedsConfirm(fert) {
      if (!fert) return null
      return (
        fert.date.source != "User Input" &&
        fert.date.value != null &&
        fert.unit.source != "User Input" &&
        fert.unit.value != null &&
        fert.amount.source != "User Input" &&
        fert.amount.value != null &&
        fert.method.source != "User Input" &&
        fert.method.value != null &&
        fert.product.source != "User Input" &&
        fert.product.value != null &&
        fert.inhibitor.source != "User Input" &&
        fert.inhibitor.value != null &&
        fert.vrt.source != "User Input" &&
        fert.vrt.value != null &&
        fert.management_plan.source != "User Input" &&
        fert.management_plan.value != null &&
        fert.N.source != "User Input" &&
        fert.N.value != null &&
        fert.P.source != "User Input" &&
        fert.P.value != null &&
        fert.K.source != "User Input" &&
        fert.K.value != null &&
        fert.S.source != "User Input" &&
        fert.S.value != null
      )
    },
    fertilizerMissingData(fert) {
      if (!fert) return null
      return (
        fert.date.source != "User Input" ||
        fert.date.value == null ||
        fert.date.value == "" ||
        fert.unit.source != "User Input" ||
        fert.unit.value == null ||
        fert.unit.value == "" ||
        fert.amount.source != "User Input" ||
        fert.amount.value == null ||
        fert.amount.value == "" ||
        fert.method.source != "User Input" ||
        fert.method.value == null ||
        fert.method.value == "" ||
        fert.product.source != "User Input" ||
        fert.product.value == null ||
        fert.product.value == "" ||
        fert.vrt.source != "User Input" ||
        fert.vrt.value == null ||
        fert.management_plan.source != "User Input" ||
        (fert.management_plan.value == null &&
          fert.N.source != "User Input" &&
          fert.N.value == null &&
          fert.P.source != "User Input" &&
          fert.P.value == null &&
          fert.K.source != "User Input" &&
          fert.K.value == null &&
          fert.S.source != "User Input" &&
          fert.S.value == null)
      )
    },
    fieldChecked(field, e) {
      if (
        this.fieldsInSelection.includes(parseInt(field.fieldId)) &&
        e == false
      ) {
        this.fieldsInSelection = this.fieldsInSelection.filter(
          f => f != parseInt(field.fieldId)
        )
      }
      if (
        e == true &&
        this.fieldsInSelection.indexOf(parseInt(field.fieldId)) == -1
      ) {
        this.fieldsInSelection.push(parseInt(field.fieldId))
      }
    },
    handleSelectAll(e, resetCropSelect = false) {
      if (e == false) this.fieldsInSelection = []
      if (e == true) {
        this.fieldsInSelection = this.selectedFields
          .map(f => f.id)
          .filter(f => !this.fieldLocks[f])
      }
      if (resetCropSelect) {
        this.cropSelect = null
      }
    },
    mapCropNames(cropIdString) {
      if (cropIdString == "No Data") {
        return cropIdString
      } else if (cropIdString.includes(",")) {
        const cropIdVals = cropIdString.split(",")
        return cropIdVals.map(v => this.cropDisplay[v]).join(", ")
      } else {
        return this.cropDisplay[cropIdString]
      }
    },
    mapOldUnits(unit) {
      if (unit == "lb") {
        return "lb/ac"
      } else if (unit == "gal") {
        return "gal/acre"
      }
      return unit
    },
    handleConfirm(field) {
      this.confirmationField = _.cloneDeep(field)
      this.confirmationApplications = _.cloneDeep(field.fertInfo)
      this.confirmationApplications.forEach(fert => {
        for (const key in fert) {
          fert[key].source = "User Input"
        }
      })
      this.showConfirmModal = true
    },
    async handleModalConfirm() {
      this.loadState = "loading"
      await this.updateFertilizer({
        fieldId: this.confirmationField.fieldId,
        year: this.year,
        fertApplications: this.confirmationApplications,
      })
      this.loadState = "confirmed"
      this.$emit("update-data-complete")
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.handleCloseConfirm()
    },
    handleCloseConfirm() {
      this.showConfirmModal = false
      this.confirmationField = null
      this.confirmationApplications = null
      this.loadState = null
    },
    handleCloseRedirect() {
      this.showRedirectModal = false
      this.redirectField = null
    },
    handleSeasonSelection(newYear) {
      this.updateYear(newYear)
    },
    disableCheckbox(noYearData) {
      if (noYearData) {
        return true
      } else {
        return false
      }
    },
    handleCropFilterButton(sharedCropPlan) {
      if (
        JSON.stringify(this.cropSelect) ==
        JSON.stringify(sharedCropPlan.crops.split(","))
      ) {
        this.cropSelect = null
        this.filterCrops = []
      } else {
        this.cropSelect = sharedCropPlan.crops.split(",")
        this.filterCrops = [this.mapCropNames(sharedCropPlan.crops)]
        if (this.applicationData.length > 0) {
          const cropValue =
            this.cropSelect != null && this.cropSelect.length == 1
              ? CROP_KEY[parseInt(this.cropSelect[0])]
              : null
          this.applicationData.forEach(o => {
            o.crop_id = cropValue
          })
        }
      }
    },
    handleAddPracticesClick() {
      if (this.showPracticesView == false) {
        this.showSaveView = false
        this.showSummaryView = false
        this.showPracticesView = true

        const cropValue =
          this.cropSelect != null && this.cropSelect.length == 1
            ? CROP_KEY[parseInt(this.cropSelect[0])]
            : null
        this.applicationData = [this._blankApplicationData(cropValue)]
      }
    },
    handleNoFertilizerClick() {
      this.applicationData = []
      this.stackPractices = false
    },
    handleSpeechToPractice(practiceResponse) {
      const practiceIndex = practiceResponse["index"]
      const practiceData = practiceResponse["response"]
      const blankPractice = this._blankApplicationData()
      const newPractice = { ...blankPractice, ...practiceData }
      if (!this.cropChoicesFiltered.includes(newPractice.crop_id)) {
        newPractice.crop_id = null
      }
      Vue.set(this.applicationData, practiceIndex, newPractice)
      this.calculateNPKforApplication(practiceIndex)
    },
    _blankApplicationData(cropValue) {
      return {
        N: 0,
        P: 0,
        K: 0,
        S: 0,
        ammonium_pct: 0,
        amount: 0,
        cost: null,
        crop_id: cropValue,
        date: this.year + "-01-01",
        inhibitor: false,
        method: "none",
        product: "none",
        slow_release: false,
        unit: null,
        vrt: false,
        management_plan: false,
        four_r: null,
        showAllInfo: true,
        // menu is boolean for v-menu model
        menu: false,
        pickerDate: this.year + "-01",
        manualNPK: { N: null, P: null, K: null, S: null },
        manualDensity: null,
      }
    },
    handleYesFertilizerClick() {
      if (this.applicationData.length == 0) {
        this.stackPractices = true
      }
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      this.applicationData = [this._blankApplicationData(cropValue)]
    },
    handleAddEventClick() {
      const cropValue =
        this.cropSelect != null && this.cropSelect.length == 1
          ? CROP_KEY[parseInt(this.cropSelect[0])]
          : null
      this.applicationData.push(this._blankApplicationData(cropValue))
    },
    handleAddMoreFertilizer() {
      this.showSummaryView = false
      this.showPracticesView = true
    },
  },
  mounted() {
    this.fieldsInSelection = this.selectedFields
      .map(f => f.id)
      .filter(f => !this.fieldLocks[f])
    if (this.recentWizardData.wizard == "fertilizer") {
      this.applicationData = _.cloneDeep(this.recentWizardData.data)
    }
  },
  watch: {
    cropChoicesFiltered(newChoices) {
      for (const practice of this.applicationData) {
        const existsInNewChoices = newChoices.some(
          choice => choice === practice.crop_id
        )
        if (!existsInNewChoices) {
          practice.crop_id = null
        }
      }
    },
    filterCrops: {
      deep: true,
      handler(myArray) {
        this.filterCropsReflection = _.cloneDeep(myArray)

        let arrayToFilter = _.cloneDeep(this.fertSettingsByField)

        if (this.filterCropsReflection.length > 0) {
          let cropsToFilter = _.flatten(
            this.filterCropsReflection.map(i => {
              return i.split(", ")
            })
          )
          arrayToFilter = arrayToFilter.filter(field =>
            cropsToFilter.every(c => field.cropNames.indexOf(c) >= 0)
          )
        }

        this.fieldsInSelection = arrayToFilter
          .map(f => parseInt(f.fieldId))
          .filter(f => !this.fieldLocks[f])
      },
    },
  },
}
</script>
<style scoped>
.input-holder {
  width: 320px;
}

.step-height {
  min-height: 400px;
  max-height: 400px;
  height: 400px;
}

.min-step-height {
  min-height: 400px;
}

.row-margin-correct {
  margin-left: 0px;
  margin-right: 0px;
}

.three {
  width: 3%;
}

.ten {
  width: 10%;
}

.twenty {
  width: 20%;
}

.cursor {
  cursor: pointer;
}

.margin-hundred {
  margin-top: 100px;
}

.machine-icon {
  width: 18px;
  height: 18px;
}
</style>
