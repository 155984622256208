<template>
  <v-card>
    <div v-if="showLoading" class="mt-3 ml-6 d-flex justify-content-center">
      <div class="text-bold mb-0">Fetching Data</div>
      <div class="spinner-border ml-4" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <v-text-field
      v-model="includedSearch"
      label="Search Programs"
      prepend-inner-icon="mdi-magnify"
      variant="outlined"
      hide-details
      single-line
    ></v-text-field>
    <v-data-table
      v-if="items.length > 0"
      :items="items"
      :headers="headers"
      :search="includedSearch"
      dense
    >
      <template slot="item.program_name" slot-scope="props">
        <span
          class="hover"
          @click="getFieldIDsFromProgram(props.item)"
          v-if="filter_type == 'corporation'"
        >
          {{ props.item.program_name }}
          <v-icon small color="blue"> mdi-filter-outline </v-icon>
        </span>
        <span v-else>
          {{ props.item.program_name }}
        </span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import _ from "lodash"

export default {
  name: "AllocationTable",
  props: ["filter_type", "programID"],
  data() {
    return {
      headers: [
        { text: "Program ID", value: "program_id" },
        { text: "Program Name", value: "program_name" },
        { text: "Channel Partner", value: "corporation_name" },
        { text: "Current Allocation", value: "allocated" },
        { text: "Total Allotment", value: "total_allotment" },
      ],
      items: [],
      showLoading: false,
      includedSearch: "",
    }
  },
  methods: {
    getFieldIDsFromProgram(e) {
      this.showLoading = true
      CarbonReadyAPI.getEAOrderFSS({ order_id: e.program_id }).then(
        response => {
          if (response.status == 200) {
            let fieldIDs = []
            response.data.forEach(fss => {
              fieldIDs.push(fss.fieldcrop.field_id)
            })
            this.includedSearch = e.program_name
            this.$emit("field-id-list", fieldIDs)
            this.showLoading = false
          }
        }
      )
    },
  },

  mounted() {
    CarbonReadyAPI.postAllocationData({
      filter_type: this.filter_type,
      programID: this.programID,
    })
      .then(response => {
        if (response.status == 200) {
          this.items = response.data
          this.$emit("num_programs", this.items.length)
        }
      })
      .catch(() => {
        this.snackbar = true
      })
  },
}
</script>

<style scoped>
.hover {
  cursor: pointer;
  color: #0095ff;
  font-weight: 500;
}
</style>
