<template>
  <ArvaModal
    name="reviewFSSDetails"
    :title="title"
    :wide="true"
    @close-modal="handleCloseModal"
  >
    <v-card class="pa-5">
      <div class="pw-error pa-2" v-if="showError">{{ errorText }}</div>
      <div v-if="items.length > 0">
        <h5>
          ARVA can match you with additional EA Programs if you try one or more
          of the following actions:
        </h5>
        <v-data-table :headers="headers" :items="items"></v-data-table>
      </div>
      <div v-if="fssProgramsSelectable.length > 0">
        <h5>Matched Programs Available to Enroll</h5>

        <v-data-table
          v-model="selectedToAdd"
          :headers="programHeaders"
          :items="fssProgramsSelectable"
          show-select
          single-select
        ></v-data-table>
        <v-btn v-if="selectedToAdd.length > 0" @click="addFields"
          >Add FieldCrop to Selected Program
        </v-btn>
      </div>
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import _ from "lodash"

export default {
  name: "ReviewFSSDetails",
  props: ["items", "title", "fssPrograms", "fssID"],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      selectedToAdd: [],
      fssProgramsSelectable: [],
      errorText: null,
      showError: false,
      fssProgramsViewable: [],
      headers: [
        { text: "Category", value: "category" },
        { text: "Action", value: "action" },
      ],
      programHeaders: [
        { text: "Program ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "% Filled", value: "pct_filled_and_signed" },
        { text: "Status", value: "status" },
      ],
    }
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
    addFields() {
      this.showError = false
      let fss_ids = []
      if (!Array.isArray(this.fssID)) {
        fss_ids = [this.fssID]
      } else {
        fss_ids = this.fssID
      }

      let payload = {
        fieldSupplyIds: fss_ids,
        order_id: this.selectedToAdd.map(e => e.id)[0],
        job_type: "add",
      }

      CarbonReadyAPI.postEAOrderFSS(payload)
        .then(response => {
          if (response.status == 200) {
            this.$emit("save-from-modal")
          } else {
            this.errorText = "Could not add Field. Program exceeds Allotment"
            this.showError = true
          }
        })
        .catch(err => {
          this.errorText = err.response.data
          //this.errorText = "Could not add Field. Program exceeds Allotment"
          this.showError = true
        })
    },
  },
  mounted() {
    this.fssProgramsSelectable = this.fssPrograms.filter(e =>
      _.includes(["executed"], e.status)
    )
    this.fssProgramsViewable = this.fssPrograms.filter(e =>
      _.includes(["open", "filled", "partially_filled", "closed"], e.status)
    )

    if (this.fssProgramsSelectable.length < 1) {
      this.errorText =
        "No Single Program Could be Found that Can Match All Selection. Please reduce Selected Fields."
      this.showError = true
    }
  },
}
</script>

<style scoped>
.pw-error {
  color: red;
  font-weight: 500;
}
</style>
